<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <div class="title1Container">
      <img :src="require('@/assets/img/xihu/homeHead.png')" class="imgRight"/>
    </div>


    <div class="title2Container">
      <div @click="toBuy()">
        去商城购药>>
      </div>
    </div>

    <ruize-menu>


    </ruize-menu>

  </div>
</template>


<script>


import {Button, NavBar, Toast, Uploader} from 'vant';
import axios from "axios";
//import axios from "axios";
import ruizeMenu from "@/components/ruize/xihu/RuizeXIhuMenu.vue"
import Cookies from "js-cookie";

import {mapMutations} from 'vuex'

export default {
  name: "Empty",
  components: {
    [Toast.name]: Toast,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader,
    ruizeMenu

  }, data() {
    return {
      pageTitle: "西湖益联保购药平台",
      claimUrl: "",
      orderListUrl: "",
      buyShopUrl: "",
      claimInfo: {},
      urlParams: ""
    }
  },
  methods: {

    getCookieOpenIdKey() {
      let qdCode = getCurrentUrlQdCode();
      if (qdCode == undefined) {
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return this.$Constants.RzCookieKey.cookieOpenId + "_" + qdCode;
    },
    async checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        debugger
        console.log("code:" + code)
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
    let res=  await     axios.post(
              this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          );

          {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          }
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid

          this.qdCode = getCurrentUrlQdCode();
          let params = {
            code: code,
            qdCode: this.qdCode
          }
          axios.post(
              this.$Constants.RzApiMethod.getUserOpenId4CutBuy, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl = "/#" + url.split("#")[1];
              window.location.href = tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:" + tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
      }
    },

   async checkPhoneStatus() {


        this.qdCode=getCurrentUrlQdCode();
      let params = {
        openId: this.openId,
        qdCode:this.qdCode,
      }
   let res= await  axios.post(
          this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      );

      {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if(this.qdCode==""){
            this.qdCode=getCurrentUrlQdCode();

            if(this.qdCode==""){
              //todo
              Toast("非法访问")
            }
          }

        } else {
          let targetUrl="/#/XihuLoginPage?qdCode="+this.qdCode;
          window.location.href =targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      }
    },
    async initBuyUrl() {
      // let url="";
        this.qdCode = getCurrentUrlQdCode();

      debugger
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
 let res=await     axios.post(
          this.$Constants.RzApiMethod.xihuJumpUrl, params
      );
      {
            console.log(res);
            if (res.data.code === 200) {
              // 跳转到输入卡密页面。
              this.buyShopUrl = res.data.data.jumpUrl;

              console.log(
                  "buyShopUrl", this.buyShopUrl
              )
            }
          }


    },
    toBuy() {
      //let url = "https://www-test.heyejk.com/heye-h5/open?channelCode=8236&params=5A0601966F45C0786ECEB808C949F69B3073A97F5725455681B3F30F6D2DCD4BC0C2FB844175205562457A11FB4C222319C02292EEA3B8372A654583862F35CEED17333A802419AF2F89FA9BA63FA2071EF7C972F16EB235D06987C485DD0F611C3C5680FB16FC852FADF8A030426495A66E06CDBCF812D39BD98F882028BC86";

      window.location.href = this.buyShopUrl;
    },
    toLipei() {
      if (this.claimUrl == "") {
        Toast("初始化中，请5秒后再点击");
      } else {
        window.location.href = this.claimUrl
      }

    },
    toShop() {
      if (this.buyShopUrl == "") {
        Toast("初始化中，请5秒后再点击");
      } else {
        window.location.href = this.buyShopUrl
      }

    },
    toOrderList() {
      if (this.orderListUrl == "") {
        Toast("初始化中，请5秒后再点击");

      } else {
        window.location.href = this.orderListUrl
      }

    },

    onClickLeft() {
      //返回
      console.error("-----:onClickLeft")
      //不支持火腿了
      //window.history.back();
    },
  ...mapMutations(['selectMenu'])
  },

  created() {
    this.checkUserAndInit();

    this.checkPhoneStatus();

    this.initBuyUrl();

    this.selectMenu("home");
  }

}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode() {
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode = getUrlParams(url, "qdCode");
debugger
  if (qdCode == undefined) {
    let url = window.location.href;

    let originUrl = url.split("#")[1];

    qdCode = getUrlParams(originUrl, "qdCode");

    console.log("qdCode:" + qdCode)

  }
  return qdCode;
}
</script>
<fqdC></fqdC>
<style scoped lang="scss">
.title2Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white !important;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  color: #FF9800;

}


.testRem1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white !important;
}

.imgRight {
  width: 100%;

  border-radius: 0px 0px 0px 0px;


}


.title1Container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;

  .textLeft {
    font-weight: 500;
    color: rgba(26, 12, 4, 0.4);
    font-family: OPPOSans-Medium, OPPOSans;
  }

  .textRight {
    font-weight: 500;
    color: #1A0C04;
    font-family: OPPOSans-Medium, OPPOSans;
  }


}


.secondContainer {
  width: 351px;
  height: 192px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;

}

.myTitle {
  border-left: solid 2px #E86922;
  margin-left: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A0C04;
  padding-left: 6px;
}


.thirdContainer {
  width: 351px;
  height: 130px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;


}

.iconContainer {


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;

  .buttonAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 98px;
    height: 62px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 14px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 22px;
    }
  }
}


</style>