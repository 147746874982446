<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <div class="title1Container">
      <img :src="require('@/assets/img/xihu/head_phone.png')" class="imgRight"/>
    </div>


    <div class="title1Container">
      <img :src="require('@/assets/img/xihu/foot_phone.png')" class="imgRight" @click="showPhone()"/>
    </div>

    <a href="tel:123456789" id="toPhone" style="display: none"></a>



  </div>
</template>


<script>


import {Button, NavBar, Toast, Uploader} from 'vant';



export default {
  name: "Empty",
  components: {
    [Toast.name]: Toast,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader,

  }, data() {
    return {
      pageTitle: "西湖益联保购药平台",
      claimUrl: "",
      orderListUrl: "",
      buyShopUrl: "",
      claimInfo: {},
      urlParams: ""
    }
  },
  methods: {
    onClickLeft() {
      //返回
      console.error("-----:onClickLeft")
      //不支持火腿了
       window.history.back();
    },

    showPhone() {
      document.getElementById("toPhone").click();
    },
  },


  created() {

  }

}


</script>
<fqdC></fqdC>
<style scoped lang="scss">
.title2Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white !important;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  color: #FF9800;

}


.testRem1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white !important;
}

.imgRight {
  width: 100%;

  border-radius: 0px 0px 0px 0px;


}


.title1Container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;

  .textLeft {
    font-weight: 500;
    color: rgba(26, 12, 4, 0.4);
    font-family: OPPOSans-Medium, OPPOSans;
  }

  .textRight {
    font-weight: 500;
    color: #1A0C04;
    font-family: OPPOSans-Medium, OPPOSans;
  }


}


.secondContainer {
  width: 351px;
  height: 192px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;

}

.myTitle {
  border-left: solid 2px #E86922;
  margin-left: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A0C04;
  padding-left: 6px;
}


.thirdContainer {
  width: 351px;
  height: 130px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;


}

.iconContainer {


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;

  .buttonAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 98px;
    height: 62px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 14px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 22px;
    }
  }
}


</style>