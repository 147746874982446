import Vue from 'vue'
import Router from 'vue-router'

// js 配置文件,全局常量
import Constants from "../commom/constants";


// xihu

import XihuCardDetail from  "@/page/ruize/xihu/XihuCardDetail.vue" ;
import XihuLoginPage from  "@/page/ruize/xihu/XihuLoginPage.vue" ;
import XihuUserCenter from  "@/page/ruize/xihu/XihuUserCenter.vue" ;
import XinhuSingleArticlePage from  "@/page/ruize/xihu/XinhuSingleArticlePage.vue" ;
import XihuOrderList from  "@/page/ruize/xihu/XihuOrderList.vue" ;
import XihuOrderDetail from  "@/page/ruize/xihu/XihuOrderDetail.vue" ;
import XihuDeliveryDetail from  "@/page/ruize/xihu/XihuDeliveryDetail.vue" ;
import XihuPhoneDetail from  "@/page/ruize/xihu/XihuPhoneDetail.vue" ;






Vue.use(Router)
Vue.prototype.$Constants = Constants

export default new Router({
    routes: [


        //xihu
        {
            path: '/XihuPhoneDetail',
            name: 'XihuPhoneDetail',
            component: XihuPhoneDetail
        },
        {
            path: '/XihuDeliveryDetail',
            name: 'XihuDeliveryDetail',
            component: XihuDeliveryDetail
        },
        {
            path: '/XihuOrderDetail',
            name: 'XihuOrderDetail',
            component: XihuOrderDetail
        },
        {
            path: '/XihuOrderList',
            name: 'XihuOrderList',
            component: XihuOrderList
        },
        {
            path: '/XihuCardDetail',
            name: 'XihuCardDetail',
            component: XihuCardDetail
        },

        {
            path: '/XihuLoginPage',
            name: 'XihuLoginPage',
            component: XihuLoginPage
        },

        {
            path: '/XihuUserCenter',
            name: 'XihuUserCenter',
            component: XihuUserCenter
        }  ,
        {
            path: '/XinhuSingleArticlePage',
            name: 'XinhuSingleArticlePage',
            component: XinhuSingleArticlePage
        }



        //mix

    ],
    // scrollBehavior (to, from, savedPosition) {
    //     return { x: 0, y: 0 }
    // }
})


